import React, { ChangeEvent, FC, useState, useEffect } from 'react';
// styles
import clsx from 'clsx';
import styles from './search.module.css';
// components
import Layout from '../layout';
import Tags from '../tags';
import PostList from '../list/list';
// types
import { PostItem, Tag, Topic, BlogPostItem } from '../Types';
// icons
import ResetIcon from './reset.inline.svg';
// helpers
import { searchPostsAndTags } from './helpers';

interface IProps {
  pathContext: {
    topics: ReadonlyArray<Topic>;
    posts: ReadonlyArray<BlogPostItem>;
    tags: ReadonlyArray<Tag>;
  };
}

const Search: FC<IProps> = ({ pathContext: { topics, posts, tags } }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleResetButtonClick = () => {
    setSearchValue('');
  };

  const searchResults = searchPostsAndTags(searchValue, posts, tags);

  return (
    <Layout topics={topics}>
      <section className={clsx('section', styles.section)}>
        <div className="inner">
          <h2 className="title">Search:</h2>

          <div className={styles.inputWrapper}>
            <input
              value={searchValue}
              onChange={handleSearchValueChange}
              type="text"
              className={styles.field}
              placeholder="What are you looking for?"
            />

            {searchValue && (
              <button
                type="button"
                className={styles.resetButton}
                onClick={handleResetButtonClick}
                // aria-label="Reset button"
              >
                <ResetIcon className={styles.resetIcon} />
              </button>
            )}
          </div>
        </div>

        <div className={clsx('inner', styles.tags)}>
          <Tags list={searchResults.tags} className={styles.tags} />
        </div>

        <div className="inner">
          <PostList list={searchResults.posts} pathname="/blog/" />

          {!searchResults.posts.length && !searchResults.tags.length && searchValue && (
            <p className={styles.noResults}>We did not find any results.</p>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Search;
