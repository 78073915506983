import { PostItem, Tag, BlogPostItem } from '../Types';

export const searchPostsAndTags = (
  searchValue: string,
  posts: ReadonlyArray<BlogPostItem>,
  tags: ReadonlyArray<Tag>
): { posts: ReadonlyArray<BlogPostItem>; tags: ReadonlyArray<Tag> } => {
  if (!searchValue) {
    return {
      posts: [],
      tags: [],
    };
  } else {
    const searchedPosts = posts.filter(({ title, tags, content, faq }) => {
      const searchValueLowercase = searchValue.toLocaleLowerCase();

      const hasSuitableTitle = title?.toLocaleLowerCase().includes(searchValueLowercase);

      const hasSuitableTag = tags?.some((tag) =>
        tag.title.toLocaleLowerCase().includes(searchValueLowercase)
      );

      const hasSuitableContent = content?.toLocaleLowerCase()?.includes(searchValueLowercase);

      const hasSuitableFAQTitle = faq?.title?.toLocaleLowerCase().includes(searchValueLowercase);

      const hasSuitableFAQ = faq?.list?.some(({ question, answer }) => {
        return (
          question.toLocaleLowerCase().includes(searchValueLowercase) ||
          answer?.toLocaleLowerCase().includes(searchValueLowercase)
        );
      });

      return (
        hasSuitableTitle ||
        hasSuitableTag ||
        hasSuitableContent ||
        hasSuitableFAQTitle ||
        hasSuitableFAQ
      );
    });

    const searchedTags = tags?.filter(({ title }) =>
      title.toLocaleLowerCase().includes(searchValue.toLowerCase())
    );

    return {
      posts: searchedPosts,
      tags: searchedTags,
    };
  }
};
